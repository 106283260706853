var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',[_c('h3',{staticClass:"mb-2 text-10"},[_vm._v(" Data Komerce ")]),_c('BRow',{staticClass:"gap-[8px]"},[_c('BCol',{attrs:{"md":"8"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tanggal bergabung "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Tanggal Bergabung","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FlatPickr',{staticClass:"form-control",attrs:{"value":_vm.joinDate,"config":{
              altInput: true,
              altFormat: 'j F Y',
              dateFormat: 'Y-m-d',
            },"placeholder":"Tanggal, Bulan, Tahun"},on:{"input":function($event){return _vm.$emit('update:joinDate', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"8"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Sektor Bisnis "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Sektor Bisnis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VSelect',{attrs:{"value":_vm.businessSectorId,"label":"partner_category_name","reduce":function (option) { return option.id; },"options":_vm.listSectorbusiness,"placeholder":"Pilih Sektor Binis"},on:{"input":function($event){return _vm.$emit('update:businessSectorId', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"8"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tipe Bisnis "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Tipe Bisnis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VSelect',{attrs:{"value":_vm.businessTypeId,"label":"name","reduce":function (option) { return option.id; },"options":_vm.listTypeBussiness,"placeholder":"Pilih Tipe Bisnis"},on:{"input":function($event){return _vm.$emit('update:businessTypeId', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"8"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nama Brand "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Nama Brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BFormInput',{attrs:{"value":_vm.brandName,"type":"text","formatter":_vm.capitalize,"placeholder":"Ketikkan nama brand kamu","state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.$emit('update:brandName', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"8"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Jumlah Tim "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Jumlah Tim","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VSelect',{attrs:{"value":_vm.teamMembers,"reduce":function (option) { return option.value; },"options":_vm.teamMemberOptions,"filterable":true,"placeholder":"Jumlah tim kamu"},on:{"input":function($event){return _vm.$emit('update:teamMembers', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"8"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Referensi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Referensi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VSelect',{attrs:{"value":_vm.reference,"reduce":function (option) { return option.value; },"options":_vm.referenceOptions,"filterable":true,"placeholder":"Pilih referensi kamu"},on:{"input":function($event){return _vm.$emit('update:reference', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"8"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Bonus ")]},proxy:true}])},[_c('ValidationProvider',{attrs:{"name":"Bonus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BFormInput',{attrs:{"value":_vm.bonus,"type":"text","formatter":_vm.capitalize,"placeholder":"Masukkan bonus kamu","state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.$emit('update:bonus', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }